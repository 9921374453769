import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useLaunchpadNFTContract } from 'hooks/useContract'
import { useCallback } from 'react'
import useCatchTxError from 'hooks/useCatchTxError'
import { useContractReads } from 'wagmi'
import { nftABI } from 'config/abi/nft'
import { getLaunchpadNFTAddress } from 'utils/addressHelpers'
import { ChainId } from '@pancakeswap/sdk'

export const useBuyLaunchpad = (index: number, onDone?: () => void) => {
  const contract = useLaunchpadNFTContract()
  const { fetchWithCatchTxError, loading: isBuying } = useCatchTxError()
  const { callWithGasPrice } = useCallWithGasPrice()

  const onBuyBNB = useCallback(
    async (value: string) => {
      const receipt = await fetchWithCatchTxError(() => {
        return callWithGasPrice(contract, 'buyNFTWithBNB', [index], { value })
      })

      if (receipt?.status && onDone) {
        onDone()
      }
    },
    [fetchWithCatchTxError, onDone, callWithGasPrice, contract, index],
  )

  const onBuyToken = useCallback(async () => {
    const receipt = await fetchWithCatchTxError(() => {
      return callWithGasPrice(contract, 'buyNFTWithUSDT', [index])
    })
    if (receipt?.status && onDone) {
      onDone()
    }
  }, [fetchWithCatchTxError, onDone, callWithGasPrice, contract, index])

  return { onBuyBNB, onBuyToken, isBuying }
}

export const useLaunchpadInfo = (index: number) => {
  const chainId = ChainId.BSC

  const { data: results, isLoading } = useContractReads({
    watch: true,
    enabled: true,
    contracts: [
      {
        chainId,
        abi: nftABI,
        address: getLaunchpadNFTAddress(chainId),
        functionName: 'itemPrices',
        args: [BigInt(index)],
      },
      {
        chainId,
        abi: nftABI,
        address: getLaunchpadNFTAddress(chainId),
        functionName: 'bnbBuyHelper',
        args: [BigInt(index)],
      },
      {
        chainId,
        abi: nftABI,
        address: getLaunchpadNFTAddress(chainId),
        functionName: 'itemStock',
        args: [BigInt(index)],
      },
      {
        chainId,
        abi: nftABI,
        address: getLaunchpadNFTAddress(chainId),
        functionName: 'unlimitedStock',
        args: [BigInt(index)],
      },
    ],
  })

  return {
    isLoading,
    priceUSDT: results?.[0]?.result ? results?.[0]?.result : undefined,
    priceBNB: results?.[1]?.result ? results?.[1]?.result : undefined,
    stock: results?.[2]?.result ? Number(results?.[2]?.result) : undefined,
    unlimitedStock: results?.[3]?.result ? Boolean(results?.[3]?.result) : undefined,
  }
}
